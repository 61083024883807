<template>
	<div class="bd-ranking-page">
		<!-- title page -->
		<section class="flat-title-page inner bd-ranking-page-top">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-md-12 bd-title">
						<h1>Rankings</h1>
					</div>
					<div class="col-md-12 bd-tags">
						<div class="badge bd-tag" :class="{active: filters.time === time.id}" v-for="(time,index_value) in times" :key="index_value" @click="filters.time = time.id;reload_list()">
							{{time.name}}
							<input type="checkbox" :checked="filters.time === time.id">
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="tf-section tf-rank">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="table-ranking">
							<div class="flex th-title">
                <div class="column1" style="display: inline-flex">
                  <h4 style="position: relative;top: 10px;left:40%;">Collection</h4>
                </div>
                <div class="column" v-for="(column,key_column) in columns" :key="key_column" @click="change_order(column.id)" >
                  <div style="display: inline-flex">
                    <h4 :style="column.id === 'volume' || column.id === 'floor_price' ? 'position: relative;top: 10px;' : ''">{{column.name}}</h4>
                    <i :class="(filters.order.direction === 'ASC' ? 'fas fa-arrow-down' : 'fas fa-arrow-up') +' bd-badge-order'" v-if="filters.order.column === column.id"></i>
                  </div>
                </div>
							</div>
			<div id="collection-box">
				<div v-for="collection in collections" v-bind:key="collection.id" class="item flex" v-show="!reload_activate || scroll_activate">
					<div class="infor-item flex column1">
						<div class="media">
							<a :href="'/collection-page/'+collection.symbol">
							<img :src="route_bo + '/storage/' + collection.logo" />
							</a>
						</div>
						<div class="content-collection pad-t-4">
							<p class="title bd-flex-column">
								<a :href="'/collection-page/'+collection.symbol">{{ collection.name }}</a>
								<span>Mint price : ◎ {{ collection.mint_price }}</span>
								<span>Mint date : {{ collection.mint_date | moment("MMM Do YY") }}</span>
							</p>
						</div>
					</div>
					<div class="column" v-for="(column,key_column) in columns" :key="key_column">
						<span :style="color_by_price(collection[column.id])" v-if="column.id.includes('evolution')">{{collection[column.id] | reduce_number(2, 'up', '--', '%')}}</span>
						<span v-else>{{collection[column.id] | reduce_number(2, 'up', '--')}}</span>
					</div>
				</div>
			</div>
              <div v-show="reload_activate">
                <div v-for="value in 5" :key="value" class="item flex">
                  <div class="infor-item flex column1">
                    <div class="media">
                      <img src="/assets/images/bluediamonds/loader-light.gif" class="bd-light" />
                      <img src="/assets/images/bluediamonds/loader-dark.gif" class="bd-dark" />
                    </div>
                    <div class="content-collection pad-t-4">
                        <p class="title mb-15 bd-pageranking-reload-collection-name">
                          <span class="badge badge-success bd-noresult-name-collection">&nbsp;</span>
                        </p>
                    </div>
                  </div>
                  <div class="column" v-for="(column,key_column) in columns" :key="key_column">
                    <span class="badge badge-success bd-noresult-price">&nbsp;</span>
                  </div>
                </div>
              </div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
  name: 'Ranking',
  components: {
  
  },
  data: function () {
	
	return {
		collections: {},
		route_server: process.env.VUE_APP_ROOT_API,
		route_bo: process.env.VUE_APP_ROOT_BO,
    reload_activate: false,
    scroll_activate: false,
    count_elements: 0,
    filters: {
      time : '24h',
      order:{
        column: 'volume',
        direction: 'DESC',
      },
      number_per_page: 5,
    },
    times:{
      0:{
        id : '24h',
        name : '24h',
      },
      1:{
        id : '7d',
        name : '7D',
      },
      2:{
        id : '30d',
        name : '30D',
      },
      3:{
        id : '3m',
        name : '3M',
      },
      4:{
        id : 'all_time',
        name : 'All time',
      },
      // 5:{
        // id : '1y',
        // name : '1Y',
      // },
    },
    columns: {
      1: {
        id: 'volume',
        name: 'Volume',
      },
      2: {
        id: 'volume_evolution',
        name: 'Volume Evolution',
      },
      3: {
        id: 'floor_price',
        name: 'Floor price',
      },
      4: {
        id: 'floor_price_evolution',
        name: 'Floor evolution',
      },
      5: {
        id: 'average_price',
        name: 'Average price',
      },
      6: {
        id: 'average_price_evolution',
        name: 'Average Evolution',
      },
    },
	}
  },
  methods: {

    reload_list: function(e,scroll = false){

      var $this = this;

      this.reload_activate = true;
      this.scroll_activate = scroll;

      var filters = $this.filters;

      if(scroll === false)
        delete filters.number_per_page;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collections/ranking',filters, config_axios).then(function(elements) {

        if(scroll === true)
          $this.collections = $this.collections.concat(elements.data.collections);
        else {
          $this.collections = elements.data.collections;
          filters.number_per_page = 5;
        }

        $this.count_elements = elements.data.count_elements;

        $this.reload_activate = false;
        $this.scroll_activate = false;

      });

    },

		change_order: function(column){

			var $this = this;

			if(column == $this.filters.order.column && $this.filters.order.direction == 'ASC')
				$this.filters.order.direction = 'DESC';

			else if(column == $this.filters.order.column && $this.filters.order.direction == 'DESC')
				$this.filters.order.direction = 'ASC';

			else{

				$this.filters.order.column = column;
        if(column === 'name')
          $this.filters.order.direction = 'ASC';
        else
          $this.filters.order.direction = 'DESC';
			}

			$this.reload_list();
		},

    scroll () {
      window.onscroll = () => {
         var id_element = 'collection-box';

         var element = document.getElementById(id_element);

         if(element === null)
           return;

        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= element.offsetHeight

        if (bottomOfWindow && this.reload_activate === false && this.count_elements > this.filters.number_per_page) {
          this.reload_list(false, true);
          this.filters.number_per_page += 5;
        }
      }
    },
    color_by_price(price){
      if(price > 0)
        return 'color:var(--primary)';
      else if(price < 0)
        return 'color:red';
    },
  },

  created: function() {
  
    var $this = this;

    $this.reload_list();
  },

  mounted() {
  window.scrollTo(0, 0)
    this.scroll();
  }
}
</script>



